
import logo from './logo.png';
import './App.css';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Image from 'material-ui-image';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';

const __default= {
  site_name: 'Eddie C Photography',
  site_url: 'https://eddiec.photography/'
}

const useStyles = makeStyles((theme) => ({
  pagePadding:{
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  responsiveImage: {
  width: '100%',
  // Without height undefined it won't work
  height: undefined,
  // figure out your image aspect ratio

},
  toolbar: {
    //borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[800],
    color: '#FFFFFF',
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
  },
  toolbarLogo: {
    height: '48px',
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    padding: theme.spacing(0, 0),
  },
  mainFeaturedPost: {
  position: 'relative',

  color: theme.palette.common.white,

  //backgroundImage: 'url(https://lh3.googleusercontent.com/TSCvCKtbpQVfE4E0JErVccM6UMRMYYJX-jsO4h2TDxUj67ezBi6flkbyNMxl8t-bHNQwpSrN=w16383)',
  //backgroundSize: 'cover',
  //backgroundRepeat: 'no-repeat',
  //backgroundPosition: 'center',
},
overlay: {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,.3)',
},
mainFeaturedPostContent: {
  position: 'relative',
  padding: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
    paddingRight: 0,
  },
},
paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
  margin: theme.spacing(1),
},
}));


function Main() {
  const classes = useStyles();
  return (
    <div className={classes.pagePadding}>
    <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/color" component={Color} />
      <Route path="/contact" component={Contact} />
      <Route component={NoMatch} />
    </Switch>
    </BrowserRouter>
    </div>
  );
}

function Header(){
  const classes = useStyles();

  return (
    <React.Fragment>
        <Toolbar className={classes.toolbar}>
          <Typography color="inherit" align="left" noWrap className={classes.toolbarTitle} component="a" href="/">
        <img src={logo} className="toolbarLogo"/>
          </Typography>


        <Typography noWrap className="toolbarLink" component="a" href="/">B&W</Typography>
        <Typography noWrap className="toolbarLink" component="a" href="/color">Color</Typography>
        <Typography noWrap className="toolbarLink" component="a" href="/contact">Contact</Typography>
        </Toolbar>
      </React.Fragment>
  )
}


function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Container maxWidth="lg">
      <Typography variant="body2" color="textSecondary" align="center" href={__default.site_url}>{'Copyright © '}{__default.site_name}{' '}{new Date().getFullYear()}{'.'}</Typography>
      </Container>
    </div>
  );
}


function NoMatch() {
  return (
    <div>Access denied.</div>
  );
}
function Home() {

  return (
    <div>
    <BlackAndWhite/>
    </div>
  );
}

function Contact() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
        <Image src='https://live.staticflickr.com/65535/49980152838_52d5d0fe4a_h.jpg' aspectRatio={(16/9)} />
        </Grid>
        <Grid item xs={7} align="left">
        <Typography noWrap>Contact me</Typography><br/>
        <Typography>If you are interested in collaborating with me in any way, please feel free to pop me an email or send direct message to me at Instagram.</Typography><br/>
        <div align="center">
          <Button variant="contained" color="default" className={classes.button} startIcon={<EmailIcon />} href="mailto:contact@eddiec.photography" target="_blank">contact@eddiec.photography</Button>
          <Button variant="contained" color="default" className={classes.button} startIcon={<InstagramIcon />} href="https://www.instagram.com/eddiec.photography/" target="_blank">@eddiec.photography</Button><br/>
        </div>
        </Grid>
      </Grid>
    </div>
  );
}

function BlackAndWhite() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Image src='https://live.staticflickr.com/65535/49980680606_9d258fdf4d_h.jpg' aspectRatio={(16/9)} />
        </Grid>
        <Grid item xs={12}>
        <Image src='https://live.staticflickr.com/65535/49980152423_cdfbf1e662_h.jpg' aspectRatio={(16/9)} />
        </Grid>
        <Grid item xs={6}>
        <Image src='https://live.staticflickr.com/65535/49980680611_9a4f6a2ab7_h.jpg' aspectRatio={(16/9)} />
        </Grid>
        <Grid item xs={6}>
        <Image src='https://live.staticflickr.com/65535/49980152878_f204df2127_h.jpg' aspectRatio={(16/9)} />
        </Grid>
        <Grid item xs={12}>
        <Image src='https://live.staticflickr.com/65535/49980681141_748238b0e5_h.jpg' aspectRatio={(16/9)} />
        </Grid>
      </Grid>
    </div>
  );
}

function Color() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Image src='https://live.staticflickr.com/65535/49980680791_68b984306a_h.jpg' aspectRatio={(16/11)} />
        </Grid>
        <Grid item xs={12}>
        <Image src='https://live.staticflickr.com/65535/49980933962_871cb71b13_h.jpg' aspectRatio={(16/11)} />
        </Grid>
        <Grid item xs={12}>
        <Image src='https://live.staticflickr.com/65535/49980681256_f256fd6114_h.jpg' aspectRatio={(16/11)}  />
        </Grid>
        <Grid item xs={12}>
        <Image src='https://live.staticflickr.com/65535/49980681111_42ad409b11_h.jpg' aspectRatio={(16/9)} />
        </Grid>
        <Grid item xs={6}>
        <Image src='https://live.staticflickr.com/65535/49980680481_d56187a3dc_h.jpg' aspectRatio={(16/11)} />
        </Grid>
        <Grid item xs={6}>
        <Image src='https://live.staticflickr.com/65535/49980933647_4229345961_h.jpg' aspectRatio={(16/11)} />
        </Grid>
        <Grid item xs={6}>
        <Image src='https://live.staticflickr.com/65535/49980153288_1c1569cb5c_h.jpg' aspectRatio={(16/11)} />
        </Grid>
        <Grid item xs={6}>
        <Image src='https://live.staticflickr.com/65535/49980152818_c78db426fe_h.jpg' aspectRatio={(16/11)} />
        </Grid>
        <Grid item xs={6}>
        <Image src='https://live.staticflickr.com/65535/49980933702_e8abb0f6ce_h.jpg' aspectRatio={(16/11)} />
        </Grid>
        <Grid item xs={6}>
        <Image src='https://live.staticflickr.com/65535/49980152683_213f795df4_h.jpg' aspectRatio={(16/11)} />
        </Grid>

      </Grid>
    </div>
  );
}
function App() {
  return (
    <div className="App">
<Header/>
<Main/>
<Footer/>
    </div>
  );
}


export default App;
